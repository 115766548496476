// Styles
import '../sass/main.scss';

var wrapperMenu = document.querySelector('.hamburger');

if(wrapperMenu){
  wrapperMenu.addEventListener('click', function(){
    wrapperMenu.classList.toggle('open');  
  });
}

var accordion = document.getElementsByClassName('accordion');
if (accordion.length > 0) {
  const accordion = document.querySelector('.accordion');
  const items = accordion.querySelectorAll('.accordion__item');

  items.forEach((item) => {
    const title = item.querySelector('.accordion__title');
    
    title.addEventListener('click', (e) => {
      const opened_item = accordion.querySelector('.is-open');
      
      // Toggle current item
      toggle_item(item); 
      
      // Close earlier opened item if exists
      if (opened_item && opened_item !== item) {
        toggle_item(opened_item);
      }
      
      
    });
  });

  const toggle_item = (item) => {
    const body = item.querySelector('.accordion__body');
    const content = item.querySelector('.accordion__content');
          
    if (item.classList.contains('is-open')) {
      body.removeAttribute('style');
      item.classList.remove('is-open');
    }else {
      body.style.height = body.scrollHeight + 'px';
      item.classList.add('is-open');
    }
  }
}

(function ($) {

  function open_minicart() {
    $('body').addClass('cart__open');
  };

  function close_minicart() {
    $('body').removeClass('cart__open');
    $('body').removeClass('filters__open');
    $('#filtersWrapper').removeClass('open');
  }

  $('.page__overlay').on( "click", close_minicart );

  $( ".minicart a" ).click(function(e) {
    e.preventDefault();
    $('body').addClass('cart__open');
    if($('body').hasClass('nav__open')) {
      $('body').removeClass('nav__open');
    }
    if($('.search__popup-container').hasClass('search__open')) {
      $('.search__popup-container').removeClass('search__open');
      $('body').removeClass('body__fixed');
    }
  });

  $( "#hamburgerWrapper" ).click(function(e) {
    e.preventDefault();
    $('body').toggleClass('nav__open');
    if($('.search__popup-container').hasClass('search__open')) {
      $('.search__popup-container').removeClass('search__open');
      $('body').removeClass('body__fixed');
    }
    if($('body').hasClass('cart__open')) {
      $('body').removeClass('cart__open');
    }
  });

  $( "#toggleSearch" ).click(function(e) {
    e.preventDefault();
    $('.search__popup-container').toggleClass('search__open');
    $('body').toggleClass('body__fixed');
    if($('body').hasClass('nav__open')) {
      $('body').removeClass('nav__open');
    }
    if($('body').hasClass('cart__open')) {
      $('body').removeClass('cart__open');
    }
  });

  $( ".close__cart" ).click(function() {
    $('body').removeClass('cart__open');
  });

  $(document).on('click', '.single_add_to_cart_button', function (e) {
      e.preventDefault();

      var $thisbutton = $(this),
          $form = $thisbutton.closest('form.cart'),
          id = $thisbutton.val(),
          product_qty = $form.find('input[name=quantity]').val() || 1,
          product_id = $form.find('input[name=product_id]').val() || id,
          variation_id = $form.find('input[name=variation_id]').val() || 0;

      var data = {
          action: 'woocommerce_ajax_add_to_cart',
          product_id: product_id,
          product_sku: '',
          quantity: product_qty,
          variation_id: variation_id,
      };

      $(document.body).trigger('adding_to_cart', [$thisbutton, data]);

      $.ajax({
          type: 'post',
          url: wc_add_to_cart_params.ajax_url,
          data: data,
          beforeSend: function (response) {
              $thisbutton.removeClass('added').addClass('loading');
          },
          complete: function (response) {
              $thisbutton.addClass('added').removeClass('loading');
          },
          success: function (response) {

              if (response.error && response.product_url) {
                  window.location = response.product_url;
                  return;
              } else {
                  $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);
                  open_minicart();
              }
          },
      });

      return false;
  });

  function open_filters() {
    $('.fiters__trigger').click(function(e) {
      $('#filtersWrapper').toggleClass('open');
      $('body').toggleClass('filters__open');
    });
  }

  function close__filters() {
    $('.close__filters, #btnApply, #btnReset').click(function(e) {
      $('#filtersWrapper').removeClass('open');
      $('body').removeClass('filters__open');
    });
  }

  $(document).on('facetwp-loaded', function() {
    $('.facetwp-facet').each(function() {
        var facet_name = $(this).attr('data-name');
        if ( 'undefined' !== typeof FWP.settings.num_choices[facet_name] && !FWP.settings.num_choices[facet_name] > 0 ) {
          $(this).parents('.filter__dropdown').remove();
        } 
    });
  });

  function filter__hover() {
    var timer;

    if ($(window).width() < 1000) {
      $('.filter__dropdown').off('mouseenter');
      $(document).on('click', '.filter__dropdown > div:first-child', function(e) {
        $(this).parent('.filter__dropdown').toggleClass('active');
      });
    }

    if ($(window).width() > 999) {
      $(".filter__dropdown").mouseenter(function(){
        var thisElement = $(this);
        timer = setTimeout(function(){
          thisElement.addClass('active'); 
        },200/* <--- the delay */)
      }).mouseleave(function(){
          clearTimeout(timer);
      });
  
      $(document).on('mouseleave', '.filter__dropdown', function(e) {
        $(this).removeClass('active');
      });
      $(document).on('click', '.filter__dropdown-footer button', function(e) {
        $(this).parents('.filter__dropdown').removeClass('active');
      });
    }
  }

  document.addEventListener('facetwp-loaded', function() {
    $.each(FWP.settings.num_choices, function(key, val) {
        var $facet = $('.facetwp-facet-' + key);
        var $parent = $facet.closest('.filter__dropdown');
        var $flyout = $facet.closest('.flyout-row');
        if ($parent.length || $flyout.length) {
            var $which = $parent.length ? $parent : $flyout;
            (1 === val) ? $which.hide() : $which.show();
        }
    });
  });

  /* Filters */
  function filter_resize() {
    if ($(window).innerWidth() > 999) {
      // if width is more the 1070px. tweek accordingly.
      var $p = $('.filters__block').detach();
      $p.prependTo($('.filter__bar'));

    }
    if ($(window).innerWidth() < 1000) {
      var $p = $('.filters__block').detach();
      $p.appendTo($('.filters__header'));
    }
  }
  $(window).resize(function resize() {
    filter__hover();
    filter_resize();
  });

  filter__hover();
  filter_resize();
  open_filters();
  close__filters();

  /* Quantity field */
  if ( ! String.prototype.getDecimals ) {
    String.prototype.getDecimals = function() {
        var num = this,
            match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if ( ! match ) {
            return 0;
        }
        return Math.max( 0, ( match[1] ? match[1].length : 0 ) - ( match[2] ? +match[2] : 0 ) );
    }
}
// Quantity "plus" and "minus" buttons
$( document.body ).on( 'click', '.plus, .minus', function() {
  var $qty        = $( this ).closest( '.quantity' ).find( '.qty'),
      currentVal  = parseFloat( $qty.val() ),
      max         = parseFloat( $qty.attr( 'max' ) ),
      min         = parseFloat( $qty.attr( 'min' ) ),
      step        = $qty.attr( 'step' );

  // Format values
  if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
  if ( max === '' || max === 'NaN' ) max = '';
  if ( min === '' || min === 'NaN' ) min = 0;
  if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;

  // Change the value
  if ( $( this ).is( '.plus' ) ) {
      if ( max && ( currentVal >= max ) ) {
          $qty.val( max );
      } else {
          $qty.val( ( currentVal + parseFloat( step )).toFixed( step.getDecimals() ) );
      }
  } else {
      if ( min && ( currentVal <= min ) ) {
          $qty.val( min );
      } else if ( currentVal > 0 ) {
          $qty.val( ( currentVal - parseFloat( step )).toFixed( step.getDecimals() ) );
      }
  }

  // Trigger change event
  $qty.trigger( 'change' );
});

/* Mobile Nav */
$(".menu-mobile-menu-container .menu-item-has-children > a:first-of-type").click(function(e){
  e.preventDefault();
  var target = $(this).parent();
  $(target).toggleClass('active');
  $(target).siblings().removeClass('active');
});

$("#s").on("change keyup paste", function(){
  $('#searchvalue').text('Risultati per: ' + $(this).val());
})

$(document).on('facetwp-loaded', function() {
  if (FWP.loaded) {
      $('html, body').animate({
          scrollTop: $('.facetwp-template').offset().top - 180
      }, 500);
  }
});

$(window).scroll(function(){ 
    if ($(this).scrollTop() > 100) { 
        $('#scroll').fadeIn(); 
    } else { 
        $('#scroll').fadeOut(); 
    } 
}); 
$('#scroll').click(function(){ 
    $("html, body").animate({ scrollTop: 0 }, 600); 
    return false; 
});

})(jQuery);

const nav = document.querySelector(".site-header");
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll <= 0) {
    nav.classList.remove(scrollUp);
    return;
  }
  
  if (currentScroll > lastScroll && !nav.classList.contains(scrollDown) && currentScroll > 130) {
    // down
    nav.classList.remove(scrollUp);
    nav.classList.add(scrollDown);
  } else if (currentScroll < lastScroll && nav.classList.contains(scrollDown)) {
    // up
    nav.classList.remove(scrollDown);
    nav.classList.add(scrollUp);
  }
  lastScroll = currentScroll;
});







jQuery(document).ready( function($) {

  // Set up variables for each of the pertinent elements
  var $searchWrap = $('.product__search-content'),
      $searchField = $('.product__search'),
      termExists = "";

  // Debounce function from https://davidwalsh.name/javascript-debounce-function
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  // Add results container and disable autocomplete on search field
  $searchWrap.append('<div class="results"></div>');
  var $searchResults = $('.search__results .results');
  $searchField.attr('autocomplete', 'off');

  // Perform search on keyup in search field, hide/show loading icon
  $searchField.keyup( function() {

    // If the search field is not empty, perform the search function
    if( $searchField.val() !== "" ) {
      termExists = true;
      doSearch();
    } else {
      termExists = false;
      $searchResults.empty();
    }
  });

  // Make search Ajax request every 200 milliseconds, output results
  var doSearch = debounce(function() {
    var query = $searchField.val();
    $.ajax({
      type: 'POST',
      url: ajaxurl, // ajaxurl comes from the localize_script we added to functions.php
      data: {
        action: 'ajax_search',
        query: query,
      },
      success: function(result) {
        if ( termExists ) {
          // `result` here is what we've specified in ajax-search.php
          $searchResults.html('<div class="results-list">' + result + '</div>');
        }
      },
      complete: function() {
        // Whether or not results are returned, hide the loading icon once the request is complete
      }
    });
  }, 200);

});